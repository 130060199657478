import {
  Avatar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import IconifyIcon from "src/components/base/IconifyIcon";
import useErrorHandler from "src/customHook/useHandleError";

import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { MenuItem as SingleMenuItem } from "src/types/types";
import { LOGOUT_SUCCESS_MESSAGE, PLEASE_WAIT_MESSAGE } from "src/util/const";
import { paths } from "src/util/path";

const menuItems: SingleMenuItem[] = [
  {
    id: 0,
    label: "Profile",
    icon: "material-symbols:person",
  },
  {
    id: 1,
    label: "My Account",
    icon: "material-symbols:account-box-sharp",
  },
  {
    id: 2,
    label: "Logout",
    icon: "uiw:logout",
  },
];

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const { handleError } = useErrorHandler();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const logout = () => {
    toast(PLEASE_WAIT_MESSAGE);
    Auth.signOut()
      .then(() => {
        toast(LOGOUT_SUCCESS_MESSAGE);
        navigate(paths.login);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleClose = (label: string) => {
    if (label === "Logout") {
      logout();
    }
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        color="inherit"
        aria-label="account"
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Avatar sx={{ width: 40, height: 40 }} />
      </IconButton>

      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {menuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.id}
            onClick={(event) => handleClose(menuItem.label)}
          >
            <ListItemIcon>
              <IconifyIcon icon={menuItem.icon} />
            </ListItemIcon>
            <Typography variant="body2">{menuItem.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AccountMenu;
