import HomeHero from "./components/hero/hero";
import HomeNewsLetter from "./components/home/newsletter";
import ServiceCard from "./components/service/service";
import Specialization from "./components/specialization/specialization";
import Technology from "./components/technology/technology";

const LandingPage = () => {
  return (
    <div>
      <HomeHero />
      <Technology />
      <ServiceCard />
      <Specialization />
      <HomeNewsLetter />
    </div>
  );
};

export default LandingPage;
