import { useTheme } from "@mui/material";
import { EChartsOption } from "echarts-for-react";
import EChartsReactCore from "echarts-for-react/lib/core";
import { BarChart } from "echarts/charts";
import { GridComponent, TooltipComponent } from "echarts/components";
import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { MutableRefObject, useMemo } from "react";
import ReactEchart from "src/components/base/ReactEchart";
import { TooltipFormatter } from "src/helpers/TooltipFormatter";

echarts.use([TooltipComponent, GridComponent, BarChart, CanvasRenderer]);

interface ChartProps {
  barChartRef: MutableRefObject<EChartsReactCore | null>;
  data: number[] | null;
  style?: {
    height?: number;
    width?: number;
  };
}

const MilesStatisticsChart = ({ barChartRef, data, style }: ChartProps) => {
  const theme = useTheme();

  const getBarChartOption = useMemo(() => {
    const barChartOption: EChartsOption = {
      color: theme.palette.primary.main,
      tooltip: {
        trigger: "axis",
        confine: true,
        axisPointer: {
          type: "line",
        },
        backgroundColor: theme.palette.neutral.dark,
        textStyle: {
          color: theme.palette.common.white,
          fontSize: theme.typography.fontSize / 1.4,
        },

        formatter: TooltipFormatter,
        padding: [2, 9, 2, 8],
        // position: function (pos: Array<number>) {
        //   return [pos[0], '30%'];
        // },
        extraCssText: "border-radius: 0.3125rem;",
      },

      grid: {
        left: "0",
        right: "1",
        // left: '-4%',
        // right: '-4%',
        top: "0",
        bottom: "12%",
      },

      textStyle: {
        fontFamily: "DM Sans",
        fontWeight: theme.typography.fontWeightRegular,
      },

      xAxis: [
        {
          type: "category",
          data: ["1 PM", "2 PM", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM"],
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: theme.palette.neutral.lighter,
            },
          },
          axisLine: {
            show: false,
          },
          axisLabel: { fontSize: theme.typography.fontSize },
        },
      ],

      yAxis: [
        {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
      ],

      series: [
        {
          type: "bar",
          barWidth: 28,
          data: [120, 80, 157, 60, 130, 40, 90],
          // data: data,
          cursor: "none",
          emphasis: {
            focus: "series",
            itemStyle: {
              color: theme.palette.primary.light,
            },
          },
        },
      ],
    };

    return barChartOption;
  }, [
    theme.palette.common.white,
    theme.palette.neutral.dark,
    theme.palette.neutral.lighter,
    theme.palette.primary.light,
    theme.palette.primary.main,
    theme.typography.fontSize,
    theme.typography.fontWeightRegular,
  ]);

  return (
    <ReactEchart
      echarts={echarts}
      option={getBarChartOption}
      ref={barChartRef}
      style={style}
    />
  );
};

export default MilesStatisticsChart;
