import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../customHook/useAuth";

export const PrivateRoute = ({ children }: PropsWithChildren) => {
  const { isLoading, redirectTo } = useAuth();
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (redirectTo) {
    return <Navigate to={redirectTo} />;
  }
  return <>{children}</>;
};
