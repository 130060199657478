import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL } from "src/util/path";

export interface ApiResponse<T> {
  data: T;
}
export class ApiRequest {
  public async request<T>(config: AxiosRequestConfig): Promise<T> {
    const response: ApiResponse<T> = await axios(config);
    return response.data;
  }

  public getBaseUrl(): string {
    return `${BASE_URL}/api/v1`;
  }
  public getLoginApi(): string {
    return `${this.getBaseUrl()}/auth/login`;
  }
  public getLogoutApi(): string {
    return `${this.getBaseUrl()}/auth/logout`;
  }
  public getResetPasswordApi(): string {
    return `${this.getBaseUrl()}/auth/resetPassword`;
  }
  public getSendOtpApi(): string {
    return `${this.getBaseUrl()}/auth/sendOtp`;
  }
  public getChangePasswordApi(): string {
    return `${this.getBaseUrl()}/auth/forceChangePassword`;
  }
  public getRegisterApi(): string {
    return `${this.getBaseUrl()}/auth/register`;
  }
  public getRoleApi(): string {
    return `${this.getBaseUrl()}/role`;
  }
  public getUserApi(): string {
    return `${this.getBaseUrl()}/user`;
  }
  public getUserDetailApi(): string {
    return `${this.getBaseUrl()}/getUserDetails`;
  }
}
