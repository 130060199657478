import { useEffect, useState } from "react";
import { LocalStorage } from "src/storage/localstorage";
import { paths } from "src/util/path";

import { CognitoUser } from "@aws-amplify/auth";
import { USER_DATA } from "src/util/const";

export const useAuth = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [redirectTo, setRedirectTo] = useState<string | null>(null);

  const user: CognitoUser = LocalStorage.getData<CognitoUser>(USER_DATA);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        if (user) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setRedirectTo(paths.login);
        }
      } catch (error) {
        console.info("Please login first !!");
        setIsLoading(false);
        setRedirectTo(paths.login);
      }
    };
    checkAuth();
  }, [user]);

  return { isLoading, redirectTo };
};
