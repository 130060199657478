import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { SendOtpFormValues } from "src/pages/authentication/sendOtp";
import { THUNK_NAME } from "src/util/const";

export const sendOtp = createAsyncThunk(
  THUNK_NAME.SEND_OTP,
  async (data: SendOtpFormValues, { rejectWithValue }) => {
    try {
      return await Auth.forgotPassword(data.email);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
