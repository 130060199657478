import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { ConfirmUserFormValue } from "src/pages/authentication/confirmUser";
import { THUNK_NAME } from "src/util/const";

export const confirmUser = createAsyncThunk(
  THUNK_NAME.CONFIRM_USER,
  async (data: ConfirmUserFormValue, { rejectWithValue }) => {
    try {
      return await Auth.confirmSignUp(data.email, data.verificationCode);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
