import React from "react";
import { Navigate } from "react-router-dom";
import { User } from "src/apiservice/dto/authDto";

import { LocalStorage } from "src/storage/localstorage";
import { CUSTOM_ROLE_ATTRIBUTE, USER_DATA } from "src/util/const";

interface RoleProps {
  permissions: string[];
  children: React.ReactNode;
}

const checkPermission = (
  requiredPermissions: string[],
  userRole: string
): boolean => {
  console.log(userRole);
  const assignedPermissions = ["LIST_USERS"];
  return requiredPermissions.every((item) =>
    assignedPermissions.includes(item)
  );
};

const RoleBasedRoute: React.FC<RoleProps> = ({ permissions, children }) => {
  const user = LocalStorage.getData<User>(USER_DATA);

  const role = user.Attributes?.find(
    (aUser) => aUser.Name === CUSTOM_ROLE_ATTRIBUTE
  )?.Value;

  if (role && !checkPermission(permissions, role)) {
    return <Navigate to="/error" />;
  }

  return <>{children}</>;
};

export default RoleBasedRoute;
