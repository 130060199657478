import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { SignUpFormValues } from "src/pages/authentication/Signup";
import { TENANT_CODE_NOT_VALID, THUNK_NAME, ZERO_STRING } from "src/util/const";

const allTenant = ["dev", "teckify"];

export const signUp = createAsyncThunk(
  THUNK_NAME.SIGN_UP,
  async (data: SignUpFormValues, { rejectWithValue }) => {
    const { email, password, name, tenantCode } = data;
    if (!allTenant.find((item) => item === tenantCode)) {
      return rejectWithValue(TENANT_CODE_NOT_VALID);
    }

    const signUpParams = {
      username: email,
      password,
      attributes: {
        email,
        name,
        "custom:tenant": tenantCode,
        "custom:approval": ZERO_STRING,
      },
    };

    try {
      const response = await Auth.signUp(signUpParams);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
