import { CognitoUser } from "@aws-amplify/auth";
import { USER_DATA } from "src/util/const";

interface Token {
  jwtToken: string;
  payload: {
    sub: string;
    iss: string;
    client_id: string;
    origin_jti: string;
    event_id: string;
    scope?: string;
    auth_time: number;
    exp: number;
    iat: number;
    jti: string;
    username: string;
  };
}

interface RefreshToken {
  token: string;
}

interface SignInUserSession {
  accessToken: Token;
  idToken: Token;
  refreshToken: RefreshToken;
  clockDrift?: number;
}

export class LocalStorage {
  static setData = <T>(key: string, value: T) => {
    localStorage.setItem(key, JSON.stringify(value));
  };
  static getData = <T>(key: string): T => {
    try {
      return JSON.parse(localStorage.getItem(key) as string) as T;
    } catch (err) {
      console.error("Parsing Error : ", err);
      return "" as T;
    }
  };
  static clear = () => {
    localStorage.clear();
  };
  static getAccessToken = (): string | undefined => {
    try {
      const cognitoUser = LocalStorage.getData<CognitoUser>(USER_DATA) as any;
      if (!cognitoUser) {
        return;
      }
      const session: SignInUserSession =
        cognitoUser.signInUserSession as SignInUserSession;

      if (!session) {
        return;
      }
      return session.accessToken.jwtToken;
    } catch (err) {
      console.log("getAccessToken : ", err);
      return;
    }
  };
}
