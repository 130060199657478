import { AxiosRequestConfig } from "axios";
import { useQuery } from "react-query";
import { ApiRequest } from "src/apiservice/axios";
import { LocalStorage } from "src/storage/localstorage";
import { HttpMethod } from "src/util/allEnum";
import { RoleTableEntity } from "../dto/roleDto";

const axios = new ApiRequest();

export const useRoleQuery = () => {
  const accessToken = LocalStorage.getAccessToken();

  return useQuery(
    ["role", accessToken],
    async () => {
      const config: AxiosRequestConfig = {
        method: HttpMethod.GET,
        url: axios.getRoleApi(),
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await axios.request<RoleTableEntity[]>(config);
      return response;
    },
    {
      retry: false,
      staleTime: 1 * 10 * 1000,
      cacheTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    }
  );
};
