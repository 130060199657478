import { Suspense } from "react";
import { Provider } from "react-redux";
import { Outlet, createBrowserRouter } from "react-router-dom";
import App from "src/App";
import LinearLoader from "src/components/loading/LinearLoader";
import Progress from "src/components/loading/Progress";
import AuthLayout from "src/layouts/auth-layout";
import DashboardPageLayout from "src/layouts/dashboard-layout";
import LandingLayout from "src/layouts/landing-layout";
import ChangePassword from "src/pages/authentication/changePassword";
import ConfirmUser from "src/pages/authentication/confirmUser";
import ForgotPassword from "src/pages/authentication/forgotPassword";
import Login from "src/pages/authentication/Login";
import SendOtp from "src/pages/authentication/sendOtp";
import Signup from "src/pages/authentication/Signup";
import Dashboard from "src/pages/dashboard/dashboard";
import SetRoleHierarchy from "src/pages/dashboard/permissions/setRoleHierarchy";
import UserDetails from "src/pages/dashboard/users/userDetails";
import Users from "src/pages/dashboard/users/users";
import ErrorPage from "src/pages/errors/ErrorPage";
import LandingPage from "src/pages/landing-page";
import store from "src/redux/store";
import { PrivateRoute } from "src/router/privateRoute";
import RoleBasedRoute from "src/router/roleBaseRoute";
import { paths } from "src/util/path";

export const routes = [
  {
    element: (
      <Suspense fallback={<Progress />}>
        <Provider store={store}>
          <App />
        </Provider>
      </Suspense>
    ),
    children: [
      {
        path: paths.dashboard,
        element: (
          <DashboardPageLayout>
            <Suspense fallback={<LinearLoader />}>
              <Outlet />
            </Suspense>
          </DashboardPageLayout>
        ),
        children: [
          {
            path: paths.dashboard,
            element: (
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            ),
          },
          {
            path: paths.users,
            element: (
              <RoleBasedRoute permissions={["LIST_USERS"]}>
                <Users />
              </RoleBasedRoute>
            ),
          },
          {
            path: paths.userDetails,
            element: (
              <RoleBasedRoute permissions={["Admin"]}>
                <UserDetails />
              </RoleBasedRoute>
            ),
          },
          {
            path: paths.setHierarchy,
            element: (
              <RoleBasedRoute permissions={["Admin"]}>
                <SetRoleHierarchy />
              </RoleBasedRoute>
            ),
          },
        ],
      },
      {
        element: (
          <LandingLayout>
            <Suspense fallback={<LinearLoader />}>
              <Outlet />
            </Suspense>
          </LandingLayout>
        ),
        children: [
          {
            path: paths.root,
            element: <LandingPage />,
          },
        ],
      },
      {
        element: <AuthLayout />,
        children: [
          {
            path: paths.login,
            element: <Login />,
          },
          {
            path: paths.signup,
            element: <Signup />,
          },
          {
            path: paths.confirmUser,
            element: <ConfirmUser />,
          },
          {
            path: paths.changePassword,
            element: <ChangePassword />,
          },
          {
            path: paths.resetPassword,
            element: <ForgotPassword />,
          },
          {
            path: paths.sendOtp,
            element: <SendOtp />,
          },
        ],
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
];

const router = createBrowserRouter(routes, { basename: "/" });

export default router;
