import { Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PermissionSelector from "./permissionSelector";
import { IOrganizationChart } from "./setRoleHierarchy";

interface NodeEditorProps {
  selectedNode: IOrganizationChart;
  newNodeName: string;
  setNewNodeName: (value: string) => void;
  handleAddNode: () => void;
  handleDeleteNode: () => void;
  getPermissionList: (permissionList: string[]) => void;
}

const NodeEditor: React.FC<NodeEditorProps> = ({
  selectedNode,
  newNodeName,
  setNewNodeName,
  handleAddNode,
  handleDeleteNode,
  getPermissionList,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

  const handlePermissionsChange = (updatedPermissions: string[]) => {
    setSelectedPermissions(updatedPermissions);
  };

  useEffect(() => {
    getPermissionList(selectedPermissions);
  }, [selectedPermissions]);

  if (!selectedNode) return null;

  return (
    <div
      ref={modalRef}
      style={{
        position: "absolute",
        top: "20px",
        right: "20px",
        padding: "10px",
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        borderRadius: "5px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h3>Edit Node: {selectedNode.name}</h3>
      <div>
        <Grid gap={10}>
          <TextField
            sx={{ m: 1, width: 300 }}
            id="outlined-basic"
            label="Role Name"
            type="text"
            value={newNodeName}
            variant="outlined"
            onChange={(e) => setNewNodeName(e.target.value)}
            fullWidth
          />
          <PermissionSelector
            handlePermissionChange={handlePermissionsChange}
            setPermissions={selectedNode?.permissions}
          />
        </Grid>
      </div>
      <div>
        <Grid sx={{ marginTop: "10px" }}>
          <Button variant="contained" onClick={handleAddNode}>
            Add Role
          </Button>
          <Button variant="text" onClick={handleDeleteNode}>
            Delete Role
          </Button>
        </Grid>
      </div>
    </div>
  );
};

export default NodeEditor;
