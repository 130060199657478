import { AxiosError } from "axios";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { ApiError } from "src/apiservice/dto/errorDto";

const useErrorHandlerHook = () => {
  const handleError = useCallback((errorOrMessage?: ApiError | string) => {
    if (typeof errorOrMessage === "string") {
      // If only a message is passed, show the toast with the message
      toast(errorOrMessage);
    } else if (errorOrMessage) {
      // If an error is passed, handle it as before
      const error = errorOrMessage;
      if (Array.isArray(error.response?.data)) {
        error.response?.data.forEach((item) => {
          if (typeof item === "object" && item !== null && "message" in item) {
            if (JSON.stringify(item.message).includes("name")) {
              const message = item.message as { name: string };
              toast(message.name);
            } else {
              const msg = item as { message: string };
              toast(msg.message);
            }
          } else {
            toast(JSON.stringify(item));
          }
        });
      } else {
        if (error.response?.data) {
          if (JSON.stringify(error.response?.data).includes("name")) {
            if (error.response?.data) {
              const message = error.response.data as {
                name: string;
              };
              toast(message.name);
            }
          } else {
            toast(JSON.stringify(error.response?.data));
          }
        } else {
          const err = error as AxiosError;
          toast(err.message);
        }
      }
    }
  }, []);

  return { handleError };
};

export default useErrorHandlerHook;
