import { Button, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Tree, { RawNodeDatum } from "react-d3-tree";
import { toast } from "react-toastify";
import { PermissionList } from "src/apiservice/allPermissions";
import { usePutRoleHierarchyMutation } from "src/apiservice/mutation/roleMutation";
import { useRoleQuery } from "src/apiservice/query/roleQuery";
import useErrorHandlerHook from "src/customHook/useHandleError";
import NodeEditor from "src/pages/dashboard/permissions/nodeEditModal";
import { PLEASE_WAIT_MESSAGE } from "src/util/const";


export interface IOrganizationChart {
  id: number;
  name: string;
  permissions: string[];
  children: IOrganizationChart[];
}

const initialOrgChart: IOrganizationChart = {
  id: 1,
  name: "Admin",
  permissions: Object.values(PermissionList),
  children: [],
};

export default function OrgChartTree() {
  const [orgChart, setOrgChart] = useState<IOrganizationChart>(initialOrgChart);
  const [selectedNode, setSelectedNode] = useState<RawNodeDatum | undefined>();
  const [newNodeName, setNewNodeName] = useState<string>("");
  const treeWrapperRef = useRef<HTMLDivElement>(null);
  const [translate, setTranslate] = useState({ x: 200, y: 200 });
  const [permissions, setPermissions] = useState<string[]>([]);

  const mutation = usePutRoleHierarchyMutation();
  const { data, error, isLoading } = useRoleQuery();

  const { handleError } = useErrorHandlerHook();

  useEffect(() => {
    if (data && data.length !== 0) {
      setOrgChart(data[0].roleData);
    }
    console.log("organization Chart", initialOrgChart);
  }, [data]);

  useEffect(() => {
    if (treeWrapperRef.current) {
      const dimensions = treeWrapperRef.current.getBoundingClientRect();
      const centerX = dimensions.width / 2;
      const centerY = dimensions.height / 4;
      setTranslate({ x: centerX, y: centerY });
    }
  }, []);

  if (mutation.isSuccess) {
    toast(JSON.stringify(mutation.data));
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    handleError(error);
    return <div>You can't set the role</div>;
  }

  const generateId = () => {
    let maxId = 0;
    const traverse = (initialChart: any) => {
      if (initialChart.id > maxId) maxId = initialChart.id;
      initialChart.children.forEach(traverse);
    };
    traverse(orgChart);

    return maxId + 1;
  };

  const nameExistsInTree = (
    node: IOrganizationChart,
    searchString: string
  ): boolean => {
    if (searchString === "") {
      return true;
    }
    if (node.name.toLowerCase() === searchString.toLowerCase()) {
      return true;
    }
    return node.children.some((child) =>
      nameExistsInTree(child as IOrganizationChart, searchString)
    );
  };

  const addNodeToTree = (
    treeNode: RawNodeDatum,
    targetName: string,
    newNode: {
      name: string;
      children: RawNodeDatum[];
    }
  ) => {
    if (treeNode.name === targetName) {
      treeNode.children = treeNode.children || [];
      treeNode.children.push(newNode);
    } else if (treeNode.children) {
      treeNode.children.forEach((child: RawNodeDatum) =>
        addNodeToTree(child, targetName, newNode)
      );
    }
  };

  const deleteNodeFromTree = (treeNode: RawNodeDatum, targetName: string) => {
    if (!treeNode.children) return;
    treeNode.children = treeNode.children.filter(
      (child: { name: any }) => child.name !== targetName
    );
    treeNode.children.forEach((child: RawNodeDatum) =>
      deleteNodeFromTree(child, targetName)
    );
  };

  const handleNodeClick = (nodeData: RawNodeDatum) => {
    if (selectedNode) {
      setSelectedNode(undefined);
    } else {
      setSelectedNode(nodeData);
    }
  };

  const handleAddNode = () => {
    if (!selectedNode) return;

    const newOrgChart: IOrganizationChart = { ...orgChart };
    const newNode: IOrganizationChart = {
      id: generateId(),
      permissions: permissions,
      name: newNodeName,
      children: [],
    };

    if (nameExistsInTree(newOrgChart, newNode.name)) return;

    addNodeToTree(newOrgChart, selectedNode.name, newNode);
    setOrgChart(newOrgChart);
    setSelectedNode(undefined);
    setNewNodeName("");
  };

  const handleDeleteNode = () => {
    if (!selectedNode) return;

    const newOrgChart = { ...orgChart };
    deleteNodeFromTree(newOrgChart, selectedNode.name);
    setOrgChart(newOrgChart);
    setSelectedNode(undefined);
  };

  const handlePermissions = (permissionList: string[]) => {
    setPermissions(permissionList);
  };

  const renderCustomNode = ({
    nodeDatum,
    toggleNode,
  }: {
    nodeDatum: RawNodeDatum;
    toggleNode: () => void;
  }) => (
    <g>
      <circle
        r={15}
        fill={
          selectedNode && selectedNode.name === nodeDatum.name
            ? "green"
            : "grey"
        }
        onClick={() => {
          handleNodeClick(nodeDatum);
          toggleNode();
        }}
      />
      <text fill="black" strokeWidth="1" x="20">
        {nodeDatum.name}
      </text>
    </g>
  );

  const saveRoleHierarchy = () => {
    toast(PLEASE_WAIT_MESSAGE);
    mutation.mutate({
      roles: orgChart,
      tenantCode: "dev",
    });
  };

  const clearRoleHierarchy = () => {
    setOrgChart(initialOrgChart);
  };

  return (
    <div>
      <div>
        <div
          id="treeWrapper"
          ref={treeWrapperRef}
          style={{ width: "100%", height: "80vh" }}
        >
          <Tree
            data={orgChart}
            orientation={"vertical"}
            collapsible={false}
            onNodeClick={(nodeData) => handleNodeClick(nodeData.data)}
            nodeSize={{ x: 100, y: 100 }}
            separation={{ siblings: 1.5, nonSiblings: 2 }}
            translate={translate}
            renderCustomNodeElement={renderCustomNode}
          />
        </div>

        <NodeEditor
          selectedNode={selectedNode as IOrganizationChart}
          newNodeName={newNodeName}
          setNewNodeName={setNewNodeName}
          handleAddNode={handleAddNode}
          handleDeleteNode={handleDeleteNode}
          getPermissionList={handlePermissions}
        />
      </div>
      <Grid
        sx={{
          display: "flex",
          gap: 5,
          justifyContent: "right",
          alignItems: "left",
        }}
      >
        <Button
          onClick={clearRoleHierarchy}
          sx={{ background: "grey", color: "black" }}
        >
          Clear Hierarchy
        </Button>
        <Button variant="contained" onClick={saveRoleHierarchy}>
          Save Hierarchy
        </Button>
      </Grid>
    </div>
  );
}
