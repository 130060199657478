import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as React from "react";
import { useEffect } from "react";
import { PermissionList } from "src/apiservice/allPermissions";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
interface PermissionSelectorProps {
  handlePermissionChange: (permission: string[]) => void;
  setPermissions: string[];
}
export default function PermissionSelector(props: PermissionSelectorProps) {
  const [permissionList, setPermissionList] = React.useState<string[]>(props.setPermissions);

  const handleChange = (event: SelectChangeEvent<typeof permissionList>) => {
    const {
      target: { value },
    } = event;
    setPermissionList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    props.handlePermissionChange(permissionList);
  }, [permissionList]);

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">
          Choose Permissions
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={permissionList}
          onChange={handleChange}
          input={<OutlinedInput label="Choose Permissions" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {Object.values(PermissionList).map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={permissionList.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
