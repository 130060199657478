import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import { FC } from "react";

const HomeNewsLetter: FC = () => {
  return (
    <Box sx={{ backgroundColor: "background.paper", py: { xs: 8, md: 10 } }}>
      <Container>
        <Box
          sx={{
            backgroundColor: "#F6F7F7",
            borderRadius: 10,
            py: { xs: 4, md: 10 },
            px: { xs: 4, md: 8 },
            textAlign: "center",
          }}
        >
          <Typography
            variant="h1"
            component="h2"
            sx={{ mb: 1, fontSize: { xs: 32, md: 42 } }}
          >
            Subscribe to Our News Letter
          </Typography>
          <Typography sx={{ mb: 6 }}>
            Subscribe to our newsletter to get information about our courses.
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-around",
              width: { xs: "100%", md: 560 },
              mx: "auto",
            }}
          >
            <InputBase
              sx={{
                backgroundColor: "background.paper",
                borderRadius: 3,
                width: "100%",
                height: 48,
                px: 2,
                mr: { xs: 0, md: 3 },
                mb: { xs: 2, md: 0 },
              }}
              placeholder="Enter your Email Address"
            />
            <Box>
              <Button>Subscribe</Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HomeNewsLetter;
