import { configureStore } from "@reduxjs/toolkit";
import { authReducers } from "./slice/authSlice";
import { userReducer } from "./slice/userSlice";

const store = configureStore({
  reducer: {
    auth: authReducers,
    user: userReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
