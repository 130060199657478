import { CognitoUser } from "@aws-amplify/auth";
import { createSlice } from "@reduxjs/toolkit";

import { signIn } from "src/redux/thunk/auth/signInThunk";
import { signUp } from "src/redux/thunk/auth/signUpThunk";
import { LocalStorage } from "src/storage/localstorage";
import { AUTH, USER_DATA } from "src/util/const";
import { changePassword } from "../thunk/auth/changePasswordThunk";
import { confirmUser } from "../thunk/auth/confirmUserThunk";
import { forgotPassword } from "../thunk/auth/forgotPasswordThunk";
import { sendOtp } from "../thunk/auth/sendOtpThunk";

interface AppState {
  userData: CognitoUser;
  loading: boolean;
  error: any;
}

const initialState: AppState = {
  userData: {} as CognitoUser,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: AUTH,
  initialState,
  reducers: {
    // setUserData(state, action) {},
  },
  extraReducers: (builder) => {
    builder
      //SignUp
      .addCase(signUp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //SignIn
      .addCase(signIn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.userData = action.payload;
        LocalStorage.clear();
        LocalStorage.setData<CognitoUser>(USER_DATA, state.userData);
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //SendOtp
      .addCase(sendOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //ForgotPassword
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //ConfirmUser
      .addCase(confirmUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(confirmUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(confirmUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //ChangePassword
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// export const { setUserData } = authSlice.actions;

export const authReducers = authSlice.reducer;
