import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { ChangePasswordFormValue } from "src/pages/authentication/changePassword";
import { NEW_PASSWORD_REQUIRED, THUNK_NAME } from "src/util/const";

export const changePassword = createAsyncThunk(
  THUNK_NAME.CHANGE_PASSWORD,
  async (data: ChangePasswordFormValue, { rejectWithValue }) => {
    try {
      const user = await Auth.signIn(data.email, data.password);
      if (user.challengeName === NEW_PASSWORD_REQUIRED) {
        return await Auth.completeNewPassword(user, data.newPassword);
      }
      return rejectWithValue(user.challengeName);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
