import { AxiosRequestConfig } from "axios";
import { useMutation, UseMutationResult } from "react-query";
import { ApiRequest } from "src/apiservice/axios";
import { IOrganizationChart } from "src/pages/dashboard/permissions/setRoleHierarchy";
import { LocalStorage } from "src/storage/localstorage";
import { HttpMethod } from "src/util/allEnum";
import { ApiError } from "../dto/errorDto";

const axios = new ApiRequest();

export const usePutRoleHierarchyMutation = (): UseMutationResult<
  string,
  ApiError,
  { roles: IOrganizationChart; tenantCode: string }
> => {
  const accessToken = LocalStorage.getAccessToken();
  return useMutation(
    (data: { roles: IOrganizationChart; tenantCode: string }) => {
      const config: AxiosRequestConfig = {
        method: HttpMethod.PUT,
        url: axios.getRoleApi(),
        data: data.roles,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      return axios.request<string>(config);
    }
  );
};
