import { createSlice } from "@reduxjs/toolkit";
import { UserData } from "src/components/table/userTable";
import { LocalStorage } from "src/storage/localstorage";
import { USER_DATA } from "src/util/const";

interface UserDataState {
  userData: UserData;
}
const initialState: UserDataState = {
  userData: {
    id: "",
    name: "",
    email: "",
    approved: "",
    role: "",
    status: "",
    dateOfRegistration: "",
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData(state, action) {
      LocalStorage.setData(USER_DATA, action.payload);
      state.userData = action.payload;
    },
  },
});

export const { setUserData } = userSlice.actions;
export const userReducer = userSlice.reducer;
