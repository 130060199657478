import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import IconifyIcon from "src/components/base/IconifyIcon";
import PasswordTextField from "src/components/common/PasswordTextField";
import Facebook from "src/components/icons/authentication/Facebook";
import useErrorHandler from "src/customHook/useHandleError";
import { AppDispatch } from "src/redux/store";
import { signUp } from "src/redux/thunk/auth/signUpThunk";
import {
  CHECK_YOUR_EMAIL_FOR_VERIFICATION_CODE,
  PLEASE_WAIT_MESSAGE,
} from "src/util/const";
import { paths } from "src/util/path";

export interface SignUpFormValues {
  name: string;
  email: string;
  password: string;
  tenantCode: string;
}

const checkBoxLabel = { inputProps: { "aria-label": "Checkbox" } };

const Signup = () => {
  const { register, handleSubmit } = useForm<SignUpFormValues>();

  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { handleError } = useErrorHandler();

  const onSubmit: SubmitHandler<SignUpFormValues> = async (data) => {
    toast(PLEASE_WAIT_MESSAGE);
    dispatch(signUp(data))
      .unwrap()
      .then((response) => {
        toast(CHECK_YOUR_EMAIL_FOR_VERIFICATION_CODE);
        navigate(paths.confirmUser);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const gotoLogin = () => {
    navigate(paths.login);
  };

  return (
    <Box sx={{ width: { xs: 1, sm: 506 }, px: { xs: 2, sm: 0 }, py: 10 }}>
      <Typography variant="h1">Get's started.</Typography>
      <Typography
        variant="subtitle1"
        component="p"
        sx={{
          color: "neutral.main",
          mt: 2,
          mb: 6.75,
        }}
      >
        Do you have an account?{" "}
        <Typography
          variant="button"
          component={Link}
          href={paths.login}
          color="secondary"
        >
          Sign in
        </Typography>
      </Typography>

      <Stack gap={1.75} mb={3} direction={{ xs: "column", sm: "row" }}>
        <Button
          variant="outlined"
          size="large"
          startIcon={<IconifyIcon icon="flat-color-icons:google" />}
          sx={{
            width: { sm: 1 / 2 },
            py: 2.375,
            px: 4.375,
            color: "neutral.dark",
          }}
        >
          Sign in with Google
        </Button>

        <Button
          variant="contained"
          size="large"
          startIcon={<Facebook />}
          sx={{
            width: { sm: 1 / 2 },
            py: 2.25,
            px: 2.875,
            bgcolor: "primary.dark",
          }}
        >
          Sign in with Facebook
        </Button>
      </Stack>

      <Divider>or</Divider>

      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Paper
          sx={(theme) => ({ padding: theme.spacing(2.5), my: 3, boxShadow: 1 })}
        >
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <InputLabel htmlFor="firstName">Name</InputLabel>
              <TextField
                fullWidth
                id="name"
                type="text"
                placeholder="Enter your name"
                autoComplete="given-name"
                {...register("name")}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor="email">Email</InputLabel>
              <TextField
                fullWidth
                id="email"
                type="text"
                placeholder="Enter your email"
                autoComplete="email"
                {...register("email")}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor="password">Password</InputLabel>
              <PasswordTextField
                fullWidth
                id="Password"
                placeholder="Enter your password"
                autoComplete="password"
                {...register("password")}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor="tenantCode">Tenant Code</InputLabel>
              <TextField
                fullWidth
                id="tenantCode"
                type="text"
                placeholder="Enter your tenant code"
                autoComplete="Tenant Code"
                {...register("tenantCode")}
              />
            </Grid>
          </Grid>
        </Paper>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={3.75}
        >
          <FormControlLabel
            control={
              <Checkbox
                {...checkBoxLabel}
                sx={{
                  color: "neutral.light",
                }}
                icon={
                  <IconifyIcon icon="fluent:checkbox-unchecked-24-regular" />
                }
                checkedIcon={
                  <IconifyIcon icon="fluent:checkbox-checked-24-regular" />
                }
              />
            }
            label={
              <Typography
                variant="h6"
                component="p"
                sx={{ color: "neutral.light" }}
              >
                Remember me
              </Typography>
            }
          />

          <Typography
            variant="h6"
            component={Link}
            color="secondary"
            onClick={gotoLogin}
          >
            Login
          </Typography>
        </Stack>

        <Button
          variant="contained"
          type="submit"
          fullWidth
          color="secondary"
          sx={{ py: 2.25 }}
        >
          <Typography variant="h4" component="span">
            Sign up
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Signup;
