export enum HttpStatusCode {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  ALREADY_REPORTED = 208,

  REDIRECTED = 302,
  SEE_OTHER = 303,
  TEMPORARY_REDIRECT = 307,

  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  CONFLICT = 409,
  PRECONDITION_FAILED = 412,
  UNPROCESSABLE_ENTITY = 422,
  LOCKED = 423,

  INTERNAL_SERVER_ERROR = 500,
}
export enum HttpMethod {
  CONNECT = "CONNECT",
  DELETE = "DELETE",
  GET = "GET",
  HEAD = "HEAD",
  OPTIONS = "OPTIONS",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT",
  TRACE = "TRACE",
}

export enum STAGE {
  DEV = "dev",
  STAGE = "stage",
  PROD = "prod",
}
export enum LambdaResponseStatusCode {
  SYNC_REQUEST_RESPONSE = 200,
  ASYNC_EVENT = 202,
  DRY_RUN = 204,
}
