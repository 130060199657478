export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const STAGE = process.env.REACT_APP_STAGE;

export const paths = {
  root: "/",
  pagesRoot: "/pages",
  errorRoot: "/error",
  dashboard: `/dashboard`,
  login: `/login`,
  signup: `/signup`,
  changePassword: `/changePassword`,
  resetPassword: "/resetPassword",
  confirmUser: "/confirmUser",
  sendOtp: "/sendOtp",
  users: "/dashboard/users",
  setHierarchy: "/dashboard/setHierarchy",
  userDetails: "/dashboard/userDetails",
};
