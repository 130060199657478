export const LOGIN_SUCCESS_MESSAGE = "Login Succeeded";
export const LOGOUT_SUCCESS_MESSAGE = "Logout Succeeded";

export const RESET_PASSWORD_SUCCESS_MESSAGE = "Password reset success";
export const OTP_SENT_MESSAGE = "Check your email to get the otp";
export const CHANGED_PASSWORD_MESSAGE = "Password changed successfully!!";
export const TEMPORARY_PASSWORD_SENT_MESSAGE =
  "A temporary password sent to your email please reset your password while login";

export const PLEASE_WAIT_MESSAGE = "Please wait...";
export const ACCESS_TOKEN = "AccessToken";
export const LOGIN_RESPONSE = "loginResponse";
export const USER_DATA = "userData";
export const BEARER = "Bearer";
export const APPLICATION_JSON = "application/json";
export const CONTENT_TYPE = "Content-Type";
export const USER_NOT_FOUND_EXCEPTION = "UserNotFoundException";
export const USER_NOT_FOUND_SIGN_IN_FIRST = "User not found , Sign Up first";
export const PASSWORD_CHANGED_SUCCESSFULLY = "Password changed successfully";
export const PASSWORD_RESET_SUCCESSFULLY = "Password reset successfully";
export const USER_REQUEST_APPROVED = "User request approved";
export const USER_DELETED = "User Deleted Successfully !!";

export const CUSTOM_ROLE_ATTRIBUTE = "custom:role";
export const CUSTOM_TENANT_ATTRIBUTE = "custom:tenant";
export const CUSTOM_APPROVAL_ATTRIBUTE = "custom:approval";

export const FAILED_GETTING_USER_DATA =
  "There is a problem getting userInformation !!";

export const ACCESS_TOKEN_NOT_VALID = "AccessToken not valid";

export const CHECK_YOUR_EMAIL_FOR_VERIFICATION_CODE =
  "Check your email for verification code !!";

export const NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED";

export const USER_IS_NOT_APPROVED_YET =
  "You are not an approved user , contact with Admin";
export const ZERO_STRING = "0";

export const TENANT_CODE_NOT_VALID = "Tenant code not valid";
export const AUTH = "auth";
export const NOW_YOU_CAN_LOGIN = "Now you can login";

export enum THUNK_NAME {
  SIGN_IN = "auth/signIn",
  SIGN_UP = "auth/signUp",
  SEND_OTP = "auth/sendOtp",
  FORGOT_PASSWORD = "auth/forgotPassword",
  CONFIRM_USER = "auth/confirmUser",
  CHANGE_PASSWORD = "auth/changePassword",
}
