import Grid from "@mui/material/Grid";
import { keyframes } from "@mui/system";
import { PropsWithChildren } from "react";

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SpinningGrid = ({ children }: PropsWithChildren) => {
  return (
    <Grid
      sx={{
        animation: `${spinAnimation} 15s linear infinite`,
      }}
    >
      {children}
    </Grid>
  );
};

export default SpinningGrid;
