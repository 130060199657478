import { Amplify } from "aws-amplify";
import { QueryClient, QueryClientProvider } from "react-query";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

Amplify.configure({
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  region: process.env.REACT_APP_REGION,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  mandatorySignIn: true,
});

const App = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <Outlet />
    </QueryClientProvider>
  );
};

export default App;
