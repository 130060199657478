import Calendar from "src/components/icons/drawer/Calendar";
import Car from "src/components/icons/drawer/Car";
import Comment from "src/components/icons/drawer/Comment";
import Doughnut from "src/components/icons/drawer/Doughnut";
import Grid from "src/components/icons/drawer/Grid";

import Settings from "src/components/icons/drawer/Settings";
import { DrawerItem } from "src/types/types";
import { paths } from "src/util/path";

export const drawerItems: DrawerItem[] = [
  {
    id: 1,
    icon: Grid,
    title: "Dashboard",
    path: paths.dashboard,
    collapsible: false,
    active: true,
  },
  {
    id: 2,
    icon: Doughnut,
    title: "Users",
    active: true,
    collapsible: true,
    subList: [{ id: 21, title: "All Users", path: paths.users, active: true }],
  },
  {
    id: 4,
    icon: Car,
    title: "Role Hierarchy",
    collapsible: true,
    active: true,
    subList: [
      {
        id: 21,
        title: "Set Hierarchy",
        path: paths.setHierarchy,
        active: true,
      },
    ],
  },
  {
    id: 8,
    icon: Calendar,
    title: "Calender",
    path: "#!",
    collapsible: false,
  },
  {
    id: 9,
    icon: Comment,
    title: "Messages",
    path: "#!",
    collapsible: false,
  },
  {
    id: 10,
    icon: Settings,
    title: "Settings",
    path: "#!",
    active: true,
    collapsible: false,
  },
];
