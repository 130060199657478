import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import IconifyIcon from "src/components/base/IconifyIcon";
import useErrorHandler from "src/customHook/useHandleError";
import { AppDispatch } from "src/redux/store";
import { sendOtp } from "src/redux/thunk/auth/sendOtpThunk";
import { OTP_SENT_MESSAGE, PLEASE_WAIT_MESSAGE } from "src/util/const";
import { paths } from "src/util/path";

export interface SendOtpFormValues {
  email: string;
}

const checkBoxLabel = { inputProps: { "aria-label": "Checkbox" } };

const SendOtp = () => {
  const { register, handleSubmit } = useForm<SendOtpFormValues>();

  const { handleError } = useErrorHandler();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const onSubmit: SubmitHandler<SendOtpFormValues> = (data) => {
    toast(PLEASE_WAIT_MESSAGE);
    dispatch(sendOtp(data))
      .unwrap()
      .then(() => {
        toast(OTP_SENT_MESSAGE);
        navigate(paths.resetPassword);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const gotoLogin = () => {
    navigate(paths.login);
  };

  return (
    <Box sx={{ width: { xs: 1, sm: 506 }, px: { xs: 2, sm: 0 }, py: 10 }}>
      <Typography variant="h1">Send OTP</Typography>

      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Paper
          sx={(theme) => ({ padding: theme.spacing(2.5), my: 3, boxShadow: 1 })}
        >
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <InputLabel htmlFor="email">Email</InputLabel>
              <TextField
                id="email"
                type="email"
                placeholder="Enter your email"
                autoComplete="email"
                fullWidth
                {...register("email")}
              />
            </Grid>
          </Grid>
        </Paper>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={3.75}
        >
          <FormControlLabel
            control={
              <Checkbox
                {...checkBoxLabel}
                sx={{
                  color: "neutral.light",
                }}
                icon={
                  <IconifyIcon icon="fluent:checkbox-unchecked-24-regular" />
                }
                checkedIcon={
                  <IconifyIcon icon="fluent:checkbox-checked-24-regular" />
                }
              />
            }
            label={
              <Typography
                variant="h6"
                component="p"
                sx={{ color: "neutral.light" }}
              >
                Remember me
              </Typography>
            }
          />
          <Typography
            variant="h6"
            component={Link}
            href="#!"
            color="secondary"
            onClick={gotoLogin}
          >
            Login
          </Typography>
        </Stack>

        <Button
          variant="contained"
          type="submit"
          fullWidth
          color="secondary"
          sx={{ py: 2.25 }}
        >
          <Typography variant="h4" component="span">
            Send Otp
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default SendOtp;
