import { Button, Link, Stack } from "@mui/material";

const ErrorPage = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={3}
      sx={{ height: "100vh" }}
    >
      <Button variant="contained" component={Link} href="/dashboard">
        You are not authorized to this page
      </Button>
    </Stack>
  );
};

export default ErrorPage;
