import { CognitoUser } from "@aws-amplify/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { LoginFormValues } from "src/pages/authentication/Login";
import {
  CUSTOM_APPROVAL_ATTRIBUTE,
  NEW_PASSWORD_REQUIRED,
  THUNK_NAME,
  USER_IS_NOT_APPROVED_YET,
  ZERO_STRING,
} from "src/util/const";

const isUserApproved = async (user: CognitoUser): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    user.getUserAttributes((err, attributes) => {
      if (err) {
        reject(err);
      }

      let isApproved: boolean = true;

      attributes?.forEach((attribute) => {
        if (attribute.Name === CUSTOM_APPROVAL_ATTRIBUTE) {
          if (attribute.Value === ZERO_STRING) {
            isApproved = false;
          }
        }
      });

      resolve(isApproved);
    });
  });
};

export const signIn = createAsyncThunk(
  THUNK_NAME.SIGN_IN,
  async (data: LoginFormValues, { rejectWithValue }) => {
    const { email, password } = data;

    try {
      const response: CognitoUser = await Auth.signIn(email, password);

      if (response.challengeName === NEW_PASSWORD_REQUIRED) {
        return rejectWithValue(NEW_PASSWORD_REQUIRED);
      }
      let isApproved: boolean = await isUserApproved(response);

      if (!isApproved) {
        return rejectWithValue(USER_IS_NOT_APPROVED_YET);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
