import { Badge, Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { Tooltip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { User, UserAttribute } from "src/apiservice/dto/authDto";
import { paths } from "src/util/path";

interface UserTableProps {
  users: User[];
}

export interface UserData {
  id: string;
  name: string;
  email: string;
  approved: string;
  role: string;
  status: string;
  dateOfRegistration: string;
}

const userTableColumn: GridColDef<UserData[][number]>[] = [
  {
    field: "id",
    headerName: "Id",
    headerAlign: "center",
    flex: 1.5,
    minWidth: 200,
    align: "center",
    sortable: false,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    sortable: false,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    sortable: false,
  },
  {
    field: "role",
    headerName: "Designation",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    sortable: false,
  },
  {
    field: "dateOfRegistration",
    headerName: "Register At",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 250,
    headerAlign: "center",
    align: "center",
    sortable: false,
  },
  {
    field: "approved",
    headerName: "Approval",
    flex: 1,
    minWidth: 150,
    align: "center",
    headerAlign: "center",
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      if (params.row.approved === "1") {
        return (
          <Tooltip title="Approved">
            <CheckCircleIcon style={{ color: "green" }} />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title="Pending">
            <Badge
              badgeContent="Pending"
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "red",
                  color: "white", // Text color inside the badge
                },
              }}
            ></Badge>
          </Tooltip>
        );
      }
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    headerAlign: "center", // Center the header text
    align: "center",
    sortable: false,
    renderCell: (params) => {
      return (
        <Badge
          badgeContent="View"
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: "gray",
              padding: 2,
              color: "white", // Text color inside the badge
              transition: "background-color 0.3s ease", // Smooth transition for hover effect
            },
            "&:hover .MuiBadge-badge": {
              backgroundColor: "darkgreen", // Change background color on hover
            },
            cursor: "pointer", // Change cursor to hand when hovering over the badge
          }}
        ></Badge>
      );
    },
  },
];

const processData = (users: User[]): UserData[] => {
  return users.map((aUser: User): UserData => {
    const attribute: UserAttribute[] = aUser.Attributes;
    const dateOfRegistration = aUser.UserCreateDate;
    const status = aUser.UserStatus;
    const id = attribute.find(
      (aAttr: UserAttribute) => aAttr.Name === "sub"
    )?.Value;
    const name = attribute.find(
      (aAttr: UserAttribute) => aAttr.Name === "name"
    )?.Value;
    const email = attribute.find(
      (aAttr: UserAttribute) => aAttr.Name === "email"
    )?.Value;
    const approved = attribute.find(
      (aAttr: UserAttribute) => aAttr.Name === "custom:approval"
    )?.Value;
    const role = attribute.find(
      (aAttr: UserAttribute) => aAttr.Name === "custom:role"
    )?.Value;
    return {
      id: id ?? "N/A",
      name: name ?? "N/A",
      email: email ?? "N/A",
      approved: approved ?? "N/A",
      role: role ?? "N/A",
      status: status,
      dateOfRegistration: new Date(dateOfRegistration).toDateString(),
    };
  });
};

export const UserTable = (props: UserTableProps) => {
  const users: UserData[] = processData(props.users);
  const navigate = useNavigate();

  const handleCellClick = (params: GridCellParams) => {
    const userData: UserData = params.row as UserData;

    navigate(paths.userDetails, { state: { userData } });
  };

  return (
    <Paper
      sx={(theme) => ({
        p: theme.spacing(2, 2.5),
        width: 1,
      })}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid>
          <Typography variant="body2">Users</Typography>
        </Grid>
        {/* 
        <Grid>
          <Grid container spacing={1}>
            <Grid item>
              <Button variant="contained">
                <Typography variant="body2">Pending</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained">
                <Typography variant="body2">Approved</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained">
                <Typography variant="body2">Canceled</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid> */}
      </Stack>

      <Box
        sx={{
          width: 1,
          mt: 1.75,
        }}
      >
        <DataGrid
          columns={userTableColumn}
          rows={users}
          onCellClick={handleCellClick} // Attach the event handler
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />
      </Box>
    </Paper>
  );
};
