import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useApproveUserMutation,
  useAssignRoleMutation,
  useDeleteUserMutation,
} from "src/apiservice/mutation/userMutation";
import { useRoleQuery } from "src/apiservice/query/roleQuery";
import { ButtonWithIcon } from "src/components/common/button/ButtonWithIcon";
import { UserData } from "src/components/table/userTable";
import useErrorHandlerHook from "src/customHook/useHandleError";
import {
  PLEASE_WAIT_MESSAGE,
  USER_DELETED,
  USER_REQUEST_APPROVED,
} from "src/util/const";
import { IOrganizationChart } from "../permissions/setRoleHierarchy";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const roleNames: string[] = [];

const getChildRoleNames = (organizationChart: IOrganizationChart): string[] => {
  const existingRole = roleNames.find(
    (item) => item === organizationChart.name
  );
  console.log("exi", existingRole, organizationChart.name);

  if (!existingRole) {
    roleNames.push(organizationChart.name);
  }

  if (!organizationChart.children[0]) {
    return roleNames;
  }
  return getChildRoleNames(organizationChart.children[0]);
};

const UserDetails = () => {
  const location = useLocation();
  const { state } = location;
  const userData = state?.userData as UserData;

  const mutationApprove = useApproveUserMutation();

  const mutationAssignRole = useAssignRoleMutation();
  const mutationDelete = useDeleteUserMutation();
  const { data, error, isLoading } = useRoleQuery();

  let allRoles: string[] = [];

  if (data) {
    allRoles = getChildRoleNames(data[0].roleData);
  }

  console.log("alll", allRoles);

  const [role, setRole] = useState<string>(userData.role);

  const { handleError } = useErrorHandlerHook();

  const [open, setOpen] = useState(false);

  if (mutationApprove.isSuccess) {
    toast(USER_REQUEST_APPROVED);
  }
  if (mutationApprove.isError) {
    handleError(mutationApprove.error);
  }
  if (mutationDelete.isSuccess) {
    toast(USER_DELETED);
  }
  if (mutationDelete.isError) {
    handleError(mutationDelete.error);
  }

  const handleApprove = () => {
    toast(PLEASE_WAIT_MESSAGE);
    mutationApprove.mutate({ email: userData.email });
  };

  const handleDelete = () => {
    toast(PLEASE_WAIT_MESSAGE);
    mutationDelete.mutate({ email: userData.email });
  };

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  if (!userData) {
    return <div>User data not found</div>;
  }

  const handleChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    setRole(value);
  };

  const assignRole = () => {
    userData.role = role;
    mutationAssignRole.mutate({ email: userData.email, role: role });
  };

  return (
    <Grid>
      <div>{JSON.stringify(userData)}</div>
      <Box>
        <Paper
          sx={(theme) => ({ padding: theme.spacing(2.5), my: 3, boxShadow: 1 })}
        >
          <Grid
            container
            spacing={2.5}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography>{userData.id}</Typography>
            </Grid>
            <Grid item>
              <Typography>{userData.name}</Typography>
            </Grid>
            <Grid item>
              <Typography>{userData.email}</Typography>
            </Grid>
            <Grid item>
              <Typography>{userData.approved}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Grid container spacing={2.5}>
        {userData.approved === "1" ? (
          "Approved"
        ) : (
          <ButtonWithIcon onClick={handleApprove} label="Approve" />
        )}
      </Grid>
      <Grid container spacing={2.5}>
        <ButtonWithIcon onClick={handleDelete} label="Delete" />
      </Grid>
      <Grid container spacing={2.5}>
        <ButtonWithIcon onClick={openModal} label="Assign Role" />
      </Grid>

      {/* Modal for Assigning Role */}
      <Dialog open={open} onClose={closeModal}>
        <DialogTitle>Assign Role</DialogTitle>
        <DialogContent>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            value={role}
            onChange={handleChange}
            input={<OutlinedInput label="Assign Role" />}
            MenuProps={MenuProps}
          >
            {allRoles.map((item) => (
              <MenuItem key={Math.random()} value={item}>
                <ListItemText primary={item} />
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            onClick={() => {
              assignRole();
              closeModal();
            }}
            color="primary"
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default UserDetails;
