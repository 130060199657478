import SendIcon from "@mui/icons-material/Send";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

interface ButtonProps {
  onClick: () => void;
  label: string;
}

export const ButtonWithIcon = ({ onClick, label }: ButtonProps) => {
  const handleButtonClicked = () => {
    onClick();
  };
  return (
    <Box sx={{ "& button": { m: 1 } }}>
      <Button
        variant="contained"
        endIcon={<SendIcon />}
        onClick={handleButtonClicked}
      >
        {label}
      </Button>
    </Box>
  );
};
