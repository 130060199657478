import { AxiosRequestConfig } from "axios";
import { useMutation, UseMutationResult } from "react-query";
import { ApiRequest } from "src/apiservice/axios";
import { ApiError } from "src/apiservice/dto/errorDto";

import { LocalStorage } from "src/storage/localstorage";
import { HttpMethod } from "src/util/allEnum";

const axios = new ApiRequest();

export const useAssignRoleMutation = (): UseMutationResult<
  string,
  ApiError,
  { email: string; role: string }
> => {
  const accessToken = LocalStorage.getAccessToken();

  return useMutation((data: { email: string; role: string }) => {
    const config: AxiosRequestConfig = {
      method: HttpMethod.PUT,
      url: axios.getUserApi(),
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return axios.request<string>(config);
  });
};

export const useApproveUserMutation = (): UseMutationResult<
  string,
  ApiError,
  { email: string }
> => {
  const accessToken = LocalStorage.getAccessToken();
  return useMutation((data: { email: string }) => {
    const config: AxiosRequestConfig = {
      method: HttpMethod.PUT,
      url: axios.getUserApi(),
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return axios.request<string>(config);
  });
};

export const useDeleteUserMutation = (): UseMutationResult<
  string,
  ApiError,
  { email: string }
> => {
  const accessToken = LocalStorage.getAccessToken();
  return useMutation((data: { email: string }) => {
    const config: AxiosRequestConfig = {
      method: HttpMethod.DELETE,
      url: axios.getUserApi(),
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return axios.request<string>(config);
  });
};
