import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { ForgotPasswordFormValue } from "src/pages/authentication/forgotPassword";
import { THUNK_NAME } from "src/util/const";

export const forgotPassword = createAsyncThunk(
  THUNK_NAME.FORGOT_PASSWORD,
  async (data: ForgotPasswordFormValue, { rejectWithValue }) => {
    try {
      return await Auth.forgotPasswordSubmit(
        data.email,
        data.verificationCode,
        data.password
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
