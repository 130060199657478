import { Grid } from "@mui/material";
import { useGetAllUserQuery } from "src/apiservice/query/userQuery";
import { UserTable } from "src/components/table/userTable";
import useErrorHandlerHook from "src/customHook/useHandleError";

const Users = () => {
  const { data, error, isLoading } = useGetAllUserQuery();

  console.log("dkfjdkj", data);
  const { handleError } = useErrorHandlerHook();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    handleError(error);
  }

  if (!data) {
    return <div>User data not found</div>;
  }

  return (
    <Grid container rowGap={3.75}>
      <Grid item xs={12}>
        <UserTable users={data} />
      </Grid>
    </Grid>
  );
};

export default Users;
